
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { firebaseErrors } from "../methods/firebaseErrors";
import * as Yup from "yup";
import googleSignIn from "../methods/googleSignIn";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    const registration = Yup.object().shape({
      first_name: Yup.string().required().label("First Name"),
      last_name: Yup.string().required().label("Second Name"),
      email: Yup.string().min(6).required().email().label("Email"),
      password: Yup.string().min(6).required().label("Password"),
      password_confirmation: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      toc: Yup.string().required("Accept Terms & Conditions to continue."),
    });

    const onSubmitRegister = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const auth = getAuth();
      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredentials) => {
          // Signed in successfully
          const user = {
            name: `${values.name} ${values.surname}`,
            email: userCredentials.user.email,
            photoURL: userCredentials.user.photoURL,
          };
          updateProfile(userCredentials.user, {
            displayName: `${values.first_name} ${values.last_name}`,
          })
            .then(() => {
              router.push({ name: "dashboard" });
            })
            .catch((error) => {
              loading.value = false;
              console.error(error, "error updating profile");
            });
        })
        .catch((error) => {
          //Deactivate indicator
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          firebaseErrors(error.code);
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
    };

    const signUpWithGoogle = () => {
      googleSignIn();
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      signUpWithGoogle,
    };
  },
});
